import {
  Box,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Link,
  Paper,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { CustomTypography } from "@next/components/custom-typography";
import CustomButton from "@next/components/custom-button/custom-button";
import { selectUpdateOrderLoading } from "@next/modules/orders/redux/selectors";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "helpers/formatDate";
import { OrderDetailMenu } from "../order-detail.menu";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { frontendUrl } from "urls";
import { OrderStepper } from "../../order-stepper";
import { getActiveStepForStepper } from "./order-detail-header.utils";
import SupplierContactsList from "@next/modules/workspace/components/supplier-contacts-list/supplier-contacts-list";
import SupplierListItem from "@next/modules/workspace/components/rfq-creation-form/supplier-list-item";
import { RevisePoModal } from "@next/modules/orders/modals/revise-po-modal";
import { getModal, modalsActions } from "@next/redux/modalsSlices";
import { OrdersModalTypes } from "@next/modules/orders/modals/types";
import {
  DUMMY_ORDER_STATUS,
  INTERNAL_NOTES_MODULE_TYPE,
} from "@next/constants";
import { ordersActions } from "@next/modules/orders/redux";
import { fileDownloadFromUrl } from "@next/utils/downloadUtils";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { GenericUser } from "@next/modules/profile/redux";
import { SharedModalTypes } from "@next/modals/types";
import {
  selectOrderByIsPortal,
  selectUpdateOrderTargetProfilesLoading,
} from "@next/modules/orders/redux/selectors";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { createStyles, makeStyles } from "@mui/styles";
import { OrderDetailHeaderModal } from "@next/modals/shared-order-detail-header-modal";
import { OrderStateTag } from "../../order-state-tag";
import CollaboratorsList from "components/workspace/buyer-rfqs-view/collaborators-list";
import TopicTitle from "@next/modules/supplier-portal/components/topic-title/topic-title";
import { OrderPOFiles } from "./order-po-files";
import { AttachFileList } from "@next/components/attach-file/attach-file-list";
import InlineFilePickerButton from "@next/components/inline-file-picker-button";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { SharedInternalNotesModal } from "@next/modals/shared-internal-notes-modal";
import { InternalNoteDropDown } from "@next/components/internal-note-dropdown/internal-note-dropdown";
import { enqueueSnackbar } from "notistack";
import { useIsLocationPath } from "@next/hooks/useIsPath";
import { NAVIGATION_FOR } from "@next/hooks/useDataForNavigation";
import { DataNavigationWithArrows } from "@next/components/data-navigation-with-arrows/data-navigation-with-arrows";
import { DistributionForSupplierContacts } from "@next/modals/shared-supplier-contact-lists-modal";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      padding: "24px",
      paddingTop: "0px",
      paddingLeft: "0px",
      paddingBottom: "0px",
      boxShadow: "none",
      border: `none`,
    },
    divider: {
      marginLeft: "-24px",
      marginRight: "-24px",
      marginTop: "10px",
      marginBottom: "16px",
    },
    listItem: {
      display: "flex",
      paddingBottom: "8px",
      paddingTop: "8px",
    },
    listItemLeft: {
      display: "flex",
      alignItems: "center",
      width: "200px",
    },
    listItemRight: {
      display: "flex",
      alignItems: "center",
      flex: 1,
    },
    attachmentButton: {
      width: "max-content",
      height: 40,
    },
    ellipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "40%",
    },
  })
);

type Props = {
  isPortal?: boolean;
};

export const OrderDetailHeader = forwardRef(({ isPortal }: Props, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | Element>(null);
  const modal = useSelector(
    getModal(SharedModalTypes.SUPPLIER_VIEW_PART_MODAL)
  );
  const partViewFileData = modal?.data;
  const orderDetail: any = useSelector(selectOrderByIsPortal(isPortal));
  const [openModal, setOpenModal] = useState(false);
  const [editedName, setEditedName] = useState(orderDetail?.name || "");
  const updateOrderLoading = useSelector(selectUpdateOrderLoading);
  const isPortalPage = useIsLocationPath(frontendUrl.portal);

  useEffect(() => setEditedName(orderDetail?.name || ""), [orderDetail]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleEditClick = () => {
    handleOpenModal();
  };

  const handleSaveChanges = () => {
    dispatch(
      ordersActions.updateOrderRequest({
        pk: orderDetail?.pk,
        name: editedName,
        qa_token: orderDetail?.qa_token,
        onSuccess: () => {
          enqueueSnackbar(t("orders:orderNameUpdated"), { variant: "success" });
          handleCloseModal();
        },
      })
    );
  };
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const onRevisePO = () => {
    dispatch(
      modalsActions.showModal({
        key: OrdersModalTypes.REVISE_PO_MODAL,
        data: { order: orderDetail },
      })
    );
  };

  const onClickDownloadAdditionalAttachments = () => {
    if (orderDetail?.rfq?.bundle_url) {
      fileDownloadFromUrl(
        orderDetail?.rfq?.bundle_url || "",
        `${orderDetail?.name}`
      );
    }
  };

  const handleUpdateOrderSupplierContactsList = (
    target_profiles: GenericUser[]
  ) => {
    const target_profiles_ids = target_profiles.map((item) => item.id);
    if (!orderDetail) return;
    dispatch(
      ordersActions.updateOrderTargetedProfilesRequest({
        rfqId: orderDetail.rfq.pk,
        target_profiles: target_profiles_ids as number[],
        supplierId: orderDetail.supplier.id,
        onSuccess: () => {
          dispatch(
            modalsActions.closeModal(
              SharedModalTypes.SUPPLIER_CONTACTS_LIST_MODAL
            )
          );
        },
      })
    );
  };

  const renderFilePickerButton = () => (
    <InlineFilePickerButton
      showSuccessSnackbar={false}
      file={null}
      onFileAdded={onPOUpload}
      btnProps={{
        variant: "outlined",
        size: "large",
        startIcon: null,
      }}
      rootClassName={classes.attachmentButton}
      multiple={false}
      pickerButtonText={
        confirmationFileExists ? t("common:update") : t("common:add")
      }
      overrideBtnText
      disableDownload
    />
  );
  const params = useParams<{ orderToken?: string; qaToken?: string }>();

  const confirmationFileExists = orderDetail?.po_confirmation_file?.file_url;

  const onPOUpload = (file: File) => {
    if (isPortal) {
      dispatch(
        ordersActions.updateSupplierPOAcknowledgementRequest({
          orderToken: params.orderToken,
          confirmation_file: file,
        })
      );
    } else {
      dispatch(
        ordersActions.updateBuyerPOAcknowledgementRequest({
          orderPk: orderDetail.pk,
          confirmation_file: file,
        })
      );
    }
  };
  useEffect(() => {
    //loads rfq notes list
    if (isPortalPage) return; // Don't load tags for portal
    dispatch(
      workspaceNextActions.getInternalNotesRequest(
        INTERNAL_NOTES_MODULE_TYPE.PO
      )
    );
  }, []);

  const isDetailPage = true;

  return (
    <Paper className={classes.root}>
      <Box height="100%" ref={ref}>
        {isPortal ? (
          <Box mt="12px" mb="12px">
            <TopicTitle company={orderDetail?.buyer} height={40} />
          </Box>
        ) : null}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height={60}
        >
          <Box display="flex" alignItems="center">
            <CustomTypography variant="h6" className={classes.ellipsis}>
              {orderDetail?.name}
            </CustomTypography>
            {!isPortal ? (
              <IconButton
                aria-label="edit"
                onClick={handleEditClick}
                size="small"
                style={{ marginLeft: "5px" }}
              >
                <EditIcon />
              </IconButton>
            ) : null}

            <Box ml="12px">
              <OrderStateTag state={orderDetail?.state} />
            </Box>

            <Box display="flex" alignItems="center" ml="12px">
              <CollaboratorsList
                data={{
                  collaborators: orderDetail?.collaborators,
                  rfqPk: orderDetail?.rfq.pk,
                  createdBy: orderDetail?.created_by,
                  poId: orderDetail?.pk,
                }}
                editMode={!hasViewerRole && !isPortal}
                size="medium"
              />
            </Box>

            {!hasViewerRole && !isPortal ? (
              <OrderDetailMenu
                anchorEl={menuAnchorEl}
                setAnchorEl={setMenuAnchorEl}
                menuActiveData={orderDetail}
                showIcon={true}
                refetchOrdersAfterAction={false}
              />
            ) : null}
            <IconButton aria-label="expand" onClick={handleToggle}>
              {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </Box>

          {!isPortal && (
            <DataNavigationWithArrows navigationFor={NAVIGATION_FOR.ORDER} />
          )}
        </Box>
        <Box className={classes.listItem}>
          {isOpen && (
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Box>
                <OrderStepper
                  activeStep={getActiveStepForStepper(orderDetail)}
                  order={orderDetail}
                  allowStepChange={!isPortal && !hasViewerRole}
                  lineItemsCount={orderDetail?.line_items_count}
                  shippedLineItemsCount={orderDetail?.shipped_line_items_count}
                />
              </Box>
              <Box mt="12px">
                {!isPortal ? (
                  <>
                    <Box className={classes.listItem}>
                      <Box className={classes.listItemLeft}>
                        <Typography variant="body2" color="textSecondary">
                          {t("project:table:row:internalNotes:header")}
                        </Typography>
                      </Box>
                      {!isPortalPage && (
                        <Box className={classes.listItemRight}>
                          <InternalNoteDropDown
                            notes={orderDetail?.notes}
                            internal_note_description={
                              orderDetail?.internal_note_description
                            }
                            paramID={orderDetail?.pk}
                            isDetailPage={isDetailPage}
                            moduleType={INTERNAL_NOTES_MODULE_TYPE.PO}
                          />
                        </Box>
                      )}
                    </Box>
                    <Box className={classes.listItem}>
                      <Box className={classes.listItemLeft}>
                        <Typography variant="body2" color="textSecondary">
                          {t("orders:orderDetail:supplier")}
                        </Typography>
                      </Box>

                      <Box className={classes.listItemRight}>
                        <SupplierListItem
                          name={orderDetail?.supplier?.name || ""}
                          avatarStyle={{ width: "32px", height: "32px" }}
                          picture={orderDetail?.supplier?.picture}
                          rootWidth="unset"
                        />
                        <SupplierContactsList
                          supplier={orderDetail?.supplier}
                          users={orderDetail?.supplier?.target_profiles}
                          views={orderDetail?.views}
                          showVisibilityBadge={true}
                          editMode={!hasViewerRole}
                          onValidate={handleUpdateOrderSupplierContactsList}
                          loadingSelector={
                            selectUpdateOrderTargetProfilesLoading
                          }
                          distributionForSupplierContacts={
                            DistributionForSupplierContacts.ORDER
                          }
                        />
                      </Box>
                    </Box>
                  </>
                ) : (
                  <Box className={classes.listItem}>
                    <Box className={classes.listItemLeft}>
                      <Typography variant="body2" color="textSecondary">
                        Created by
                      </Typography>
                    </Box>

                    <Box className={classes.listItemRight}>
                      <SupplierListItem
                        name={orderDetail?.buyer?.name || ""}
                        avatarStyle={{ width: "32px", height: "32px" }}
                        picture={orderDetail?.buyer?.picture}
                      />
                    </Box>
                  </Box>
                )}

                {orderDetail?.updated_delivery_date ? (
                  <Box className={classes.listItem}>
                    <Box className={classes.listItemLeft}>
                      <Typography variant="body2" color="textSecondary">
                        {t("orders:orderDetail:promisedDelivery")}
                      </Typography>
                    </Box>

                    <Box className={classes.listItemRight}>
                      {formatDate(
                        orderDetail?.updated_delivery_date,
                        "DD-MMM-YYYY"
                      )}
                    </Box>
                  </Box>
                ) : null}

                <Box className={classes.listItem}>
                  <Box className={classes.listItemLeft}>
                    <Typography variant="body2" color="textSecondary">
                      {t("orders:orderDetail:purchaseOrder")}
                    </Typography>
                  </Box>

                  <Box className={classes.listItemRight}>
                    <OrderPOFiles
                      poFiles={orderDetail?.files}
                      onRevisePO={onRevisePO}
                      isPortal={isPortal}
                      hasViewerRole={hasViewerRole}
                    />
                  </Box>
                </Box>

                <Box className={classes.listItem}>
                  <Box className={classes.listItemLeft}>
                    <Typography variant="body2" color="textSecondary">
                      {t("orders:orderDetail:orderConfirmation")}
                    </Typography>
                  </Box>

                  <Box className={classes.listItemRight}>
                    {confirmationFileExists && (
                      <AttachFileList
                        files={[orderDetail?.po_confirmation_file]}
                        disableFileItemMarginBottom
                        downloadable
                        getDisablePaddingBottom
                      />
                    )}
                    {!isPortal ? (
                      <Tooltip
                        title={t("orders:orderDetail:tooltip:confirmPO")}
                      >
                        {renderFilePickerButton()}
                      </Tooltip>
                    ) : (
                      confirmationFileExists && renderFilePickerButton()
                    )}
                  </Box>
                </Box>

                {orderDetail?.rfq?.bundle_url ? (
                  <Box className={classes.listItem}>
                    <Box className={classes.listItemLeft}>
                      <Typography variant="body2" color="textSecondary">
                        {t("orders:orderDetail:additionalAttachments")}
                      </Typography>
                    </Box>

                    <Box className={classes.listItemRight}>
                      <>
                        <Chip
                          size="small"
                          icon={<InsertDriveFileOutlinedIcon />}
                          label={"zip"}
                        />

                        <Box ml="8px">
                          <Link
                            href="#"
                            onClick={() =>
                              onClickDownloadAdditionalAttachments()
                            }
                          >
                            <Typography variant="body2">
                              {orderDetail?.name}
                            </Typography>
                          </Link>
                        </Box>
                      </>
                    </Box>
                  </Box>
                ) : null}

                {!isPortal &&
                orderDetail?.rfq?.status !== DUMMY_ORDER_STATUS &&
                orderDetail?.rfq?.display_name ? (
                  <Box className={classes.listItem}>
                    <Box className={classes.listItemLeft}>
                      <Typography variant="body2" color="textSecondary">
                        {t("orders:orderDetail:requestReference")}
                      </Typography>
                    </Box>

                    <Box className={classes.listItemRight}>
                      <Link
                        target="_blank"
                        href={`${frontendUrl.bundleView}/${orderDetail?.rfq?.pk}`}
                      >
                        <Typography variant="body2">
                          {orderDetail?.rfq?.display_name}
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                ) : null}

                <RevisePoModal />

                <OrderDetailHeaderModal
                  isModalOpen={modal}
                  partViewFileData={partViewFileData}
                />
              </Box>
            </Collapse>
          )}
        </Box>
        <SharedInternalNotesModal
          moduleType={INTERNAL_NOTES_MODULE_TYPE.PO}
          isDetailPage={isDetailPage}
          paramID={orderDetail?.pk}
        />
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="edit-order-name-dialog"
          maxWidth="sm"
          fullWidth
        >
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="order-name"
              label="Display Name"
              type="text"
              fullWidth
              value={editedName}
              inputProps={{ maxLength: 255 }}
              onChange={(e) => setEditedName(e.target.value)}
            />
          </DialogContent>
          <DialogActions style={{ padding: "4px 24px 20px" }}>
            <CustomButton
              onClick={handleSaveChanges}
              color="primary"
              variant="outlined"
              startIcon={<DoneIcon />}
              disabled={!editedName.trim()}
              loading={updateOrderLoading}
            >
              {t("common:save")}
            </CustomButton>
          </DialogActions>
        </Dialog>
      </Box>
    </Paper>
  );
});
