import React, { useEffect } from "react";
import { MUIDatePicker } from "@next/components/mui-date-picker";
import { Menu } from "@mui/material";

type Props = {
  anchorEl: null | HTMLElement;
  row: any;
  handleChange?: (date: Date | null) => void;
  handleOnClose?: () => void;
  currentSelectionDate: string | Date;
  setCurrentSelectionDate: (currentSelectedDate: string) => void;
};

export const SelectDateMenu: React.FC<Props> = ({
  anchorEl,
  row,
  handleChange,
  handleOnClose,
  currentSelectionDate,
  setCurrentSelectionDate,
}) => {
  const openMenu = Boolean(anchorEl);
  const [isPastDate, setIsPastDate] = React.useState(false);

  const poeDate = (row: any) => {
    if (row?.poe) {
      return row?.poe;
    } else if (row?.poe_per_line) {
      return row?.poe_per_line;
    } else {
      return "";
    }
  };

  useEffect(() => {
    setCurrentSelectionDate(poeDate(row));
    if (
      row?.compliance_status === "fully_compliant" ||
      row?.compliance_status === "compliant_with_rfd" ||
      row?.compliance === "fully_compliant" ||
      row?.conform_to_a2x === "conform_to_a2x"
    ) {
      setIsPastDate(true);
    } else {
      setIsPastDate(false);
    }
  }, [row]);

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleOnClose}
    >
      <MUIDatePicker
        variant="static"
        value={currentSelectionDate}
        disablePast={!isPastDate}
        disableFuture={isPastDate}
        onChange={handleChange}
      />
    </Menu>
  );
};
