import React, { useCallback, useState } from "react";
import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro-v5";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SelectDateMenu } from "../table-menu/select-date-menu/select-date-menu";
import { EvidenceMenu } from "../table-menu/evidence-menu/evidence-menu";
import { SelectOptionMenu } from "../table-menu/select-option-menu/select-option-menu";
import {
  EvidenceFileObj,
  PartialStep,
  Questionnaire,
  SaveSpecOfScopeRequest,
  Spec,
  SpecificationTableRow,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectIsFormCompleted,
  selectQuestionnaireName,
  selectSpecsOfScope,
  selectSpecsOfScopeLoading,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  getLeadTimeForImplementationFormTableColumns,
  selectComplianceMenuItems,
} from "./lead-time-for-implementation-form-table.columns";
import { formatDate } from "helpers/formatDate";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./lead-time-for-implementation-form-table.styled";

type Props = {
  hideCompletedTableLines: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
};

export const LeadTimeForImplementationFormTable: React.FC<Props> = ({
  hideCompletedTableLines,
  page,
  pageSize,
  setPage,
}) => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const currentQuestionnaire = useSelector(selectQuestionnaireName);
  const apiRef = useGridApiRef();
  const questionnaireName = useSelector(selectQuestionnaireName);
  const isFormCompleted = useSelector(selectIsFormCompleted);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const step = steps[activeFormStepIndex];
  const columns =
    getLeadTimeForImplementationFormTableColumns(questionnaireName);
  const [anchorElForDateMenu, setAnchorElForDateMenu] =
    useState<null | HTMLElement>(null);
  const [anchorElForEvidenceMenu, setAnchorElForEvidenceMenu] =
    useState<null | HTMLElement>(null);
  const [anchorElForOptionMenu, setAnchorElForOptionMenu] =
    useState<null | HTMLElement>(null);
  const [row, setRow] = useState<SpecificationTableRow>();
  const [field, setField] = useState<string>("");
  const [cellModesModel, setCellModesModel] =
    React.useState<GridCellModesModel>({});
  const isLoadingFetchSpecs = useSelector(selectSpecsOfScopeLoading);
  const specsOfScopeData = useSelector(selectSpecsOfScope);
  const [specOfScopeData, setSpecOfScopeData] =
    useState<SaveSpecOfScopeRequest>();
  const [currentSelectionDate, setCurrentSelectionDate] = useState<string>();

  const rows = specsOfScopeData?.results?.data;

  const handleCellClick = useCallback(
    (params: GridCellParams, event: React.MouseEvent<HTMLElement>) => {
      if (params.field === "poe_per_line" && params.row.compliance_status) {
        setRow(params.row);
        setField(params.field);
        setAnchorElForDateMenu(event.currentTarget);
      } else if (
        (params.field === "evidence" || params.field === "coc") &&
        params.row.compliance_status &&
        params.row.compliance_status !== "fully_compliant" &&
        params.row.compliance_status !== "compliant_with_rfd"
      ) {
        setRow(params.row);
        setAnchorElForEvidenceMenu(event.currentTarget);
      } else if (params.field === "compliance_status") {
        setRow(params.row);
        setField(params.field);
        setAnchorElForOptionMenu(event.currentTarget);
      }

      if (!params.colDef.editable) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    [isFormCompleted, rows]
  );

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const specification_id = newRow?.specification_id;
    let updatedField = null;
    let updatedFieldKey = null;
    Object.keys(newRow).forEach((key) => {
      if (newRow[key] !== oldRow[key]) {
        updatedField = { [key]: newRow[key] };
        updatedFieldKey = [key];
      }
    });

    if (updatedField) {
      dispatch(
        vendorManagementActions.saveSpecOfScopeRequest({
          specificationId: specification_id,
          spec: updatedField,
          companyId: companyId,
          onSuccess: onUpdateSuccess,
        })
      );
    }
    return newRow;
  };

  const handleClickComplianceOption = (menuItem: string) => {
    const specification_id = row?.specification_id;
    const formData = new FormData();
    formData.append("compliance_status", menuItem);

    dispatch(
      vendorManagementActions.saveSpecOfScopeRequest({
        specificationId: specification_id,
        spec: formData as Spec,
        companyId: companyId,
        onSuccess: onUpdateSuccess,
      })
    );
    setAnchorElForOptionMenu(null);
  };

  const handleClickDateSelection = (date: Date | null) => {
    const formattedDate = formatDate(date, "YYYY-MM-DD");
    setCurrentSelectionDate(formattedDate);

    const specification_id = row?.specification_id;
    const spec: Spec = {
      poe_per_line: formattedDate,
    };
    setSpecOfScopeData({
      specificationId: specification_id,
      spec: spec,
      companyId: companyId,
    });
  };
  const handleOnClose = () => {
    if (currentSelectionDate !== row?.poe_per_line) {
      dispatch(
        vendorManagementActions.saveSpecOfScopeRequest({
          ...specOfScopeData,
          onSuccess: onUpdateSuccess,
        })
      );
    }
    setAnchorElForDateMenu(null);
  };

  const handleSaveCommentAndFile = (
    comment: string,
    file: File | "" | EvidenceFileObj
  ) => {
    const specification_id = row?.specification_id;
    const formData = new FormData();
    if (currentQuestionnaire !== Questionnaire.PomDistributor) {
      formData.append("evidence_comment", comment);
      if (!(file as EvidenceFileObj)?.file_name) {
        // if file is saved before, file is an evidence file object, not a binary file, no need to save again
        formData.append("evidence_file", file as File);
      }
    } else {
      formData.append("coc_comment", comment);
      if (!(file as EvidenceFileObj)?.file_name) {
        // if file is saved before, file is an coc file object, not a binary file, no need to save again
        formData.append("coc_file", file as File);
      }
    }
    dispatch(
      vendorManagementActions.saveSpecOfScopeRequest({
        specificationId: specification_id,
        spec: formData as Spec,
        companyId: companyId,
        onSuccess: onUpdateSuccess,
      })
    );
    setAnchorElForEvidenceMenu(null);
  };

  const onUpdateSuccess = () => {
    reFetchTableDataToGetUpdatedCount();
    checkInputChange();
  };

  const reFetchTableDataToGetUpdatedCount = () => {
    dispatch(
      vendorManagementActions.fetchSpecsOfScopeRequest({
        companyId: companyId,
        pagination: { page: page, pageSize: pageSize },
        stepId: step.step,
        filter_completed: hideCompletedTableLines,
        isForFetchingToGetCountData: true,
      })
    );
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else {
      updateFieldStatusOfStepForSaveAndCompletion("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const step = steps[activeFormStepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
  };

  return (
    <>
      <S.StyledDataGridPro
        apiRef={apiRef}
        autoHeight
        autoPageSize
        headerHeight={48}
        rowHeight={32}
        rows={rows || []}
        columns={columns}
        pagination
        paginationMode="server"
        pageSize={pageSize}
        page={page - 1}
        rowCount={specsOfScopeData?.count ? specsOfScopeData.count : 0}
        rowsPerPageOptions={[pageSize]}
        onPageChange={(newPage) => setPage(newPage + 1)}
        disableSelectionOnClick
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processRowUpdate}
        loading={isLoadingFetchSpecs}
        getRowId={(row) => row.specification_id}
      />

      <SelectOptionMenu
        anchorEl={anchorElForOptionMenu}
        setAnchorEl={setAnchorElForOptionMenu}
        row={row}
        field={field}
        menuItems={selectComplianceMenuItems}
        handleClick={handleClickComplianceOption}
      />

      <SelectDateMenu
        anchorEl={anchorElForDateMenu}
        row={row}
        currentSelectionDate={currentSelectionDate}
        setCurrentSelectionDate={setCurrentSelectionDate}
        handleChange={handleClickDateSelection}
        handleOnClose={handleOnClose}
      />

      <EvidenceMenu
        anchorEl={anchorElForEvidenceMenu}
        row={row}
        fieldComment={
          currentQuestionnaire !== Questionnaire.PomDistributor
            ? "evidence_comment"
            : "coc_comment"
        }
        fieldFile={
          currentQuestionnaire !== Questionnaire.PomDistributor
            ? "evidence_file"
            : "coc_file"
        }
        setAnchorEl={setAnchorElForEvidenceMenu}
        handleSaveCommentAndFile={handleSaveCommentAndFile}
      />
    </>
  );
};
